import { collection, doc, onSnapshot } from 'firebase/firestore'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import useSWRSubscription from 'swr/subscription'
import { randomInteger } from '@/core/utils'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

export const useFirebaseMeta = ({
  entityType,
  id,
  mutator,
  jitter = true,
  paused,
  ignoreDate
}: {
  entityType: string
  id?: string
  mutator?: (data: any) => any
  jitter?: boolean
  paused?: boolean
  ignoreDate?: boolean
}) => {
  const { firestore } = useFirebase()

  const { data, error } = useSWRSubscription(
    [entityType, id, paused],
    ([entityType, id, paused], { next }) => {
      if (paused) {
        next(null, {})
        return () => {}
      }
      const db = firestore
      const q = collection(db, entityType)
      const d = doc(q, id)

      const unsub = onSnapshot(d, res => {
        const data = res.data()
        // skip meta docs older than 5 minutes old to avoid potential "backwards" data
        // due to missed updates from the API to Firestore
        if (
          !ignoreDate &&
          (!data?.dateModified ||
            Math.abs(moment().diff(data.dateModified, 'm')) > 5)
        ) {
          next(null, {})
        } else {
          next(null, data)
        }
      })
      return () => unsub()
    },
    { suspense: false }
  )

  useEffect(() => {
    if (!data || !mutator) return
    const to = setTimeout(
      () => {
        // skip dateModified to keep from updating for non-changes due to combo metas
        const { dateModified, ...rest } = data
        return mutator?.(rest)
      },
      jitter ? randomInteger(1, 10000) : 0
    )

    return () => {
      clearTimeout(to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, jitter])

  return { data, error }
}
